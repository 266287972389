import { createBrowserRouter } from "react-router-dom";
import Home from "./Home/Home";
import AboutUs from "./About Us/AboutUs";
import Gallery from "./Gallery/Gallery";
import Programs from "./Programs/Programs";
import ContactUs from "./Contact Us/ContactUs";
import Navbar from "./Navbar/Navbar";



const router = createBrowserRouter(
    [
        {
            path: "/",
            element: <Navbar/>,
            children: [
                {
                    path: "/",
                    element: <Home/>,
                    index: true
                },
                {
                    path: "/about",
                    element: <AboutUs/>
                },
                {
                    path: "/gallery",
                    element: <Gallery/>
                },
                {
                    path: "/programs",
                    element: <Programs/>
                },
                {
                    path: "/contact",
                    element: <ContactUs/>
                }
            ]
        }
    ]
);

export default router;