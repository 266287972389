import React, { useState } from "react";
import { NavLink, Outlet } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="navbar">
        <NavLink className={"navbarHeading"} to={"/"}>
          CodBEE
        </NavLink>
        <div className={isOpen ? "navBar active" : "navBar"}>
          <ul className={`navbarSetting ${isOpen ? "navbarListing active" : "navbarListing"}`}>
            <li>
              <NavLink
                to={"/"}
                className={({ isActive }) =>
                  `listItem ${isActive ? "activeLink" : ""}`
                }
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/about"}
                className={({ isActive }) =>
                  `listItem ${isActive ? "activeLink" : ""}`
                }
              >
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/gallery"}
                className={({ isActive }) =>
                  `listItem ${isActive ? "activeLink" : ""}`
                }
              >
                Gallery
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/programs"}
                className={({ isActive }) =>
                  `listItem ${isActive ? "activeLink" : ""}`
                }
              >
                Programs
              </NavLink>
            </li>
            <li>
              <NavLink
                to={"/contact"}
                className={({ isActive }) =>
                  `listItem ${isActive ? "activeLink" : ""}`
                }
              >
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <NavLink className={"navbarCallLink"} to={"/contact"}>
          <div className="navbarCallPosition">
            <div className="navbarCallIcon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="38"
                height="20"
                fill="#FFFFFF"
                className="bi bi-telephone-fill"
                viewBox="0 0 16 16"
              >
                <path
                  fillRule="evenodd"
                  d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"
                />
              </svg>
            </div>
            <div className="navbarCallDetails">
              <p>Need Help?</p>
              <h5>Call Us!</h5>
            </div>
          </div>
        </NavLink>
        <div className="navbarMenu" onClick={toggleMenu}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            fill="currentColor"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
            />
          </svg>
        </div>
      </div>
      <Outlet />
    </div>
  );
};

export default Navbar;
