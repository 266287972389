import React from "react";
import Footer from "../Footer/Footer";

const AboutUs = () => {

  return (
    <div>
      <section className="aboutCodebee">
        <div className="aboutCodebee1">
          <p>ABOUT CodBEE</p>
        </div>
        <div className="visionDetails">
          <div className="visionImage">
            <img src="businessCycle.png" className="logo1" />
          </div>
          <div className="vision">
            <h4>Our Vision</h4>
            <p>
              At CodBEE, our vision is to empower students of all backgrounds to
              pursue their interests in STEM fields. By connecting with students
              from diverse communities and providing them with the resources and
              support they need, we aim to cultivate the next generation of
              innovators and leaders
            </p>
          </div>
        </div>
      </section>
      <section className="shapingCodebee">
        <div className="shapingCodebee1">
          <h4>
            CodBEE:
            <br />
            Shaping India's Digital
            <br />
            Tomorrow!
          </h4>
          <p>
            CodBEE is a leader in providing high-quality training for students
            interested in Coding. Robotics, and
            <br />
            Creative Thinking. Our innovative approach to STEM education equips
            students with the knowledge and
            <br />
            skills necessary to thrive in today's fastpaced, technology-driven
            world.
          </p>
        </div>
        <div className="shapingCodebee2">
          <div className="shapingCodebee3">
            <img src="family.png" className="hexagonImage" />
            <p>
              Expert Trainers Committed to Your
              <br />
              Success
            </p>
          </div>
          <div className="shapingCodebee3">
            <img src="smile.png" className="hexagonImage" />
            <p>
              Syllabus Crafted by IIT Alumni &<br />
              Subject Matter Experts
            </p>
          </div>
          <div className="shapingCodebee3">
            <img src="handshake.png" className="hexagonImage" />
            <p>State-of-the-Art Robotics/STEM Lab</p>
          </div>
          <div className="shapingCodebee3">
            <img src="economy.png" className="hexagonImage" />
            <p>
              Affordable Pricing with
              <br />
              Uncompromised Quality
            </p>
          </div>
        </div>
      </section>
      <section className="inspiringContent">
        <div className="inspiringContent1">
          <h4>
            Inspiring Young Trailblazers:
            <br />
            Robotics and AI for Tomorrow's
            <br />
            Leaders
          </h4>
        </div>
        <div className="inspiringContent2">
          <div className="inspiringContent3">
            <div className="inspiringContent4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#FFFFFF"
                class="bi bi-star-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>
            </div>
            <div className="inspiringContent44">
              <p>Stay Ahead of the Curve</p>
              <span>
                Adaptability is your secret weapon in a fast-paced digital
                world!
              </span>
            </div>
          </div>
          <div className="inspiringContent3">
            <div className="inspiringContent4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                fill="#FFFFFF"
                class="bi bi-box-seam"
                viewBox="0 0 16 16"
              >
                <path d="M8.186 1.113a.5.5 0 0 0-.372 0L1.846 3.5l2.404.961L10.404 2zm3.564 1.426L5.596 5 8 5.961 14.154 3.5zm3.25 1.7-6.5 2.6v7.922l6.5-2.6V4.24zM7.5 14.762V6.838L1 4.239v7.923zM7.443.184a1.5 1.5 0 0 1 1.114 0l7.129 2.852A.5.5 0 0 1 16 3.5v8.662a1 1 0 0 1-.629.928l-7.185 2.874a.5.5 0 0 1-.372 0L.63 13.09a1 1 0 0 1-.63-.928V3.5a.5.5 0 0 1 .314-.464z" />
              </svg>
            </div>
            <div className="inspiringContent44">
              <p>Unlock the Mystery of Tech</p>
              <span>
                Simplifying complex concepts lets you harness technology like a
                pro!
              </span>
            </div>
          </div>
          <div className="inspiringContent3">
            <div className="inspiringContent4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                fill="#FFFFFF"
                class="bi bi-cpu"
                viewBox="0 0 16 16"
              >
                <path d="M5 0a.5.5 0 0 1 .5.5V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2h1V.5a.5.5 0 0 1 1 0V2A2.5 2.5 0 0 1 14 4.5h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14v1h1.5a.5.5 0 0 1 0 1H14a2.5 2.5 0 0 1-2.5 2.5v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14h-1v1.5a.5.5 0 0 1-1 0V14A2.5 2.5 0 0 1 2 11.5H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2v-1H.5a.5.5 0 0 1 0-1H2A2.5 2.5 0 0 1 4.5 2V.5A.5.5 0 0 1 5 0m-.5 3A1.5 1.5 0 0 0 3 4.5v7A1.5 1.5 0 0 0 4.5 13h7a1.5 1.5 0 0 0 1.5-1.5v-7A1.5 1.5 0 0 0 11.5 3zM5 6.5A1.5 1.5 0 0 1 6.5 5h3A1.5 1.5 0 0 1 11 6.5v3A1.5 1.5 0 0 1 9.5 11h-3A1.5 1.5 0 0 1 5 9.5zM6.5 6a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5z" />
              </svg>
            </div>
            <div className="inspiringContent44">
              <p>Think Like a Tech Genius</p>
              <span>
                Coding and robotics sharpen your analytical skills and spark
                creativity!
                <br />
              </span>
            </div>
          </div>
        </div>
        <div className="inspiringContent6">
          <p>
            Learning robotics and AI cultivates creativity, problem-solving, and
            teamwork from an early age, setting the stage for future STEM
            careers. It develops hands-on skills, innovation, and boosts
            confidence, equipping students to thrive in today's tech-driven
            world-essential for success in the modern workforce!
          </p>
          <img src="inspiringImage.jpg" alt="children showing teamwork" />
        </div>
      </section>

      {/* ********************TEAM SECTION ******************************/}

      <section className="teamSection">
        <h4>Our Team</h4>
        <div className="cardWrapper">
          <div className="profileCard">
            <img
              src="ankur-singh-team.jpg"
              alt="member's Photo"
              className="profileImage"
            />
            <div className="cardDetails">
              <h4>Ankur Singh</h4>
              <span>Director</span>
              <p>
                Ankur Singh is the Director of CodBEE, bringing a wealth of
                expertise in technology and business management. With an M.Tech
                from AIU and an MBA from Academi Europae Open University, Ankur
                is dedicated to driving innovation and strategic growth within
                the startup, shaping its vision and direction.
              </p>
            </div>
            <div className="iconProfile">
              <a href="https://www.linkedin.com/in/erankursing">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill=""
                  class="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="profileCard">
            <img
              src="prakhar-singh-team.jpg"
              alt="member's Photo"
              className="profileImage"
            />
            <div className="cardDetails">
              <h4>Prakhar Singh</h4>
              <span>Robotics Expert</span>
              <p>
                Prakhar Singh is the Program Manager and Robotics Expert at
                CodBEE, leveraging his B.Tech from NIT Allahabad to spearhead
                cutting-edge projects in robotics. With a passion for technology
                and innovation, Prakhar plays a pivotal role in advancing
                CodBEE's mission and enhancing its product offerings.
              </p>
            </div>
            <div className="iconProfile">
              <a href="https://www.linkedin.com/in/prakhar-singh234">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill=""
                  class="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="profileCard">
            <img
              src="aman-shrivastav-team.jpg"
              alt="member's Photo"
              className="profileImage"
            />
            <div className="cardDetails">
              <h4>Aman Shrivastav</h4>
              <span>Program Manager</span>
              <p>
                Aman Shrivastav is the Program Manager for Expansion at CodBEE,
                where he drives strategic initiatives to enhance the startup's
                growth and reach. With a keen focus on innovative solutions and
                market development, Aman plays a crucial role in shaping
                CodBEE's vision for the future.
              </p>
            </div>
            <div className="iconProfile">
              <a href="https://www.linkedin.com/in/aman-srivastava-2a67a1214">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill=""
                  class="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="profileCard">
            <img
              src="pratyush-sahay-team.JPG"
              alt="member's Photo"
              className="profileImage"
            />
            <div className="cardDetails">
              <h4>Pratyush Sahay</h4>
              <span>Core IT team</span>
              <p>
                Pratyush Sahay is a key member of the core IT team at CodBEE,
                armed with a B.Tech from BIT Mesra. His technical expertise and
                innovative mindset contribute significantly to the development
                and implementation of CodBEE's technology solutions, driving the
                startup's mission forward.
              </p>
            </div>
            <div className="iconProfile">
              <a href="https://www.linkedin.com/in/pratyush-sahay">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill=""
                  class="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="profileCard">
            <img
              src="myImage.jpg"
              alt="member's Photo"
              className="profileImage"
            />
            <div className="cardDetails">
              <h4>SHIVAM MISHRA</h4>
              <span>Developer</span>
              <p>
                Shivam Mishra is a Developer at CodBEE, holding a B.Tech from
                IIIT Dharwad. With a strong foundation in software development,
                Shivam is dedicated to creating innovative solutions that
                enhance CodBEE's offerings and support its growth in
                the tech landscape.
              </p>
            </div>
            <div className="iconProfile">
              <a href="https://www.linkedin.com/in/shivammishra7/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill=""
                  class="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </a>
            </div>
          </div>
          <div className="profileCard">
            <img
              src="suraj-rawat-team.jpg"
              alt="member's Photo"
              className="profileImage"
            />
            <div className="cardDetails">
              <h4>Suraj Rawat</h4>
              <span>Developer</span>
              <p>
                Suraj Rawat is a Developer at CodBEE, equipped with a B.Tech
                from IIIT Dharwad. His passion for coding and problem-solving
                drives the development of cutting-edge solutions that contribute
                to CodBEE's innovative projects and overall success.
              </p>
            </div>
            <div className="iconProfile">
              <a href="https://www.linkedin.com/in/suraj-rawat-69a59a241/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill=""
                  class="bi bi-linkedin"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  );
};

export default AboutUs;
