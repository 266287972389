import React from "react";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

const Programs = () => {

  return (
    <div className="programSection">
      <section className="pSection">
        <span>PROGRAMS</span>
        <h5>Our Courses</h5>
        <p>
          At CodBEE, we offer engaging courses tailored for students from Class
          3 to Class 12. Our programs in robotics, coding, and <br /> STEAM
          education foster critical thinking, creativity, and problem-solving
          skills, preparing young learners for a technology- <br />
          driven future.
        </p>
        <div className="pSection1">
          <div className="pSection2">
            <div className="pSectionImage">
              <img src="basic-robotics-thumbnail.jpeg.jpg" alt="basic robotics thumbnail" />
            </div>
            <h6>Basic Robotics</h6>
            <div className="pSection3">
              <span>Class 3-12</span>
              <li>25 Modules</li>
              <li>Flexible Timing</li>
            </div>
            <div className="pSection4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#F9BE25"
                class="bi bi-star-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>
              <span>Highly Rated</span>
            </div>
          </div>
          <div className="pSection2">
            <div className="pSectionImage">
              <img src="coding-adventure-thumbnail.jpeg.jpg" alt="coding adventure thumbnail" />
            </div>
            <h6>Coding Adventures</h6>
            <div className="pSection3">
              <span>Class 3-12</span>
              <li>12 Modules</li>
              <li>Flexible Timing</li>
            </div>
            <div className="pSection4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#F9BE25"
                class="bi bi-star-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>
              <span>Highly Rated</span>
            </div>
          </div>
          <div className="pSection2">
            <div className="pSectionImage">
              <img src="robo-explorers-thumbnail.jpeg.jpg" alt="" />
            </div>
            <h6>Robo Explorers</h6>
            <div className="pSection3">
              <span>Class 3-12</span>
              <li>25 Modules</li>
              <li>Flexible Timing</li>
            </div>
            <div className="pSection4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="#F9BE25"
                class="bi bi-star-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z" />
              </svg>
              <span>Highly Rated</span>
            </div>
          </div>
        </div>
      </section>
      <section className="our_programs">
        <div className="original1">
          <p className="head1">OUR PROGRAMS</p>
          <h5 className="cat1">
            Discover Our Engaging <br /> Robotics and AI Programs
          </h5>
          <span className="explore">
            Explore our diverse offerings at CodBEE, where hands-on learning
            meets expert guidance, ensuring students are well-prepared for the
            future in robotics, coding, and STEAM education.
          </span>
          <div className="news0">
            <div className="news">
              <div className="news1">
                <div className="iconSVG">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#FFFFFF"
                    class="bi bi-backpack4"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 9.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5zm1 .5v3h6v-3h-1v.5a.5.5 0 0 1-1 0V10z" />
                    <path d="M8 0a2 2 0 0 0-2 2H3.5a2 2 0 0 0-2 2v1c0 .52.198.993.523 1.349A.5.5 0 0 0 2 6.5V14a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6.5a.5.5 0 0 0-.023-.151c.325-.356.523-.83.523-1.349V4a2 2 0 0 0-2-2H10a2 2 0 0 0-2-2m0 1a1 1 0 0 0-1 1h2a1 1 0 0 0-1-1M3 14V6.937q.24.062.5.063h4v.5a.5.5 0 0 0 1 0V7h4q.26 0 .5-.063V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1m9.5-11a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1z" />
                  </svg>
                </div>
                <div className="news2">
                  <h6 className="cer">Robotics & Coding Classes</h6>
                  <p>
                    Our classes provide a solid <br /> foundation in robotics
                    and coding,
                    <br /> blending practical skills with core <br /> concepts.
                  </p>
                </div>
              </div>
              <div className="news1">
                <div className="iconSVG">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#FFFFFF"
                    class="bi bi-camera-video"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2zm11.5 5.175 3.5 1.556V4.269l-3.5 1.556zM2 4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1z"
                    />
                  </svg>
                </div>
                <div className="news2">
                  <h6 className="cer">Robotics/STEAM Lab Setup</h6>
                  <p>
                    We create interactive labs that let <br /> students explore
                    Science, <br /> Technology, Engineering, Arts, and <br />{" "}
                    Math hands-on.
                  </p>
                </div>
              </div>
              <div className="news1">
                <div className="iconSVG">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#FFFFFF"
                    class="bi bi-house"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 .708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z" />
                  </svg>
                </div>
                <div className="news2">
                  <h6 className="cer">Certifications</h6>
                  <p>
                    Our programs certify students’ <br /> skills in robotics,
                    coding, and <br /> STEAM, showcasing their <br />
                    proficiency and expertise
                  </p>
                </div>
              </div>
              <div className="news1">
                <div className="iconSVG">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#FFFFFF"
                    class="bi bi-sun"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 11a3 3 0 1 1 0-6 3 3 0 0 1 0 6m0 1a4 4 0 1 0 0-8 4 4 0 0 0 0 8M8 0a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 0m0 13a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2A.5.5 0 0 1 8 13m8-5a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 .5.5M3 8a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1 0-1h2A.5.5 0 0 1 3 8m10.657-5.657a.5.5 0 0 1 0 .707l-1.414 1.415a.5.5 0 1 1-.707-.708l1.414-1.414a.5.5 0 0 1 .707 0m-9.193 9.193a.5.5 0 0 1 0 .707L3.05 13.657a.5.5 0 0 1-.707-.707l1.414-1.414a.5.5 0 0 1 .707 0m9.193 2.121a.5.5 0 0 1-.707 0l-1.414-1.414a.5.5 0 0 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .707M4.464 4.465a.5.5 0 0 1-.707 0L2.343 3.05a.5.5 0 1 1 .707-.707l1.414 1.414a.5.5 0 0 1 0 .708" />
                  </svg>
                </div>
                <div className="news2">
                  <h6 className="cer">Dedicated Expert Trainer On Campus</h6>
                  <p>
                    Experienced, on-campus trainers <br /> deliver personalized
                    guidance, <br /> ensuring every student's success. <br />{" "}
                  </p>
                </div>
              </div>
              <div className="news1">
                <div className="iconSVG">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#FFFFFF"
                    class="bi bi-copy"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1zM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1z"
                    />
                  </svg>
                </div>
                <div className="news2">
                  <h6 className="cer">Curriculum by IIT Alumni & SME</h6>
                  <p>
                    A syllabus designed by IITIANS <br /> and industry experts,
                    keeping up <br /> with the latest trends and standards.{" "}
                    <br />{" "}
                  </p>
                </div>
              </div>
              <div className="news1">
                <div className="iconSVG">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="23"
                    fill="#FFFFFF"
                    class="bi bi-laptop"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5" />
                  </svg>
                </div>
                <div className="news2">
                  <h6 className="cer">Daily Essential Topics</h6>
                  <p>
                    Structured lessons that build <br />
                    consistent knowledge in robotics, <br />
                    coding, and STEM concepts.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <span className="bottom">
            At CodBEE, we foster your child’s passion for computer science
            through age-appropriate learning. <br /> Our programs enable
            students to explore coding and create apps, games, and websites.{" "}
            <br /> Join us for hands-on experiences that prepare them for
            success in robotics, coding, and STEAM education.
          </span>
        </div>
      </section>
      <section className="our_instructors">
        <div className="original1">
          <p className="head1">Our Instructors</p>
          <h5 className="cat1">Meet Our Team of Experts</h5>
          <span className="explore">
            At CodBEE, a dedicated team of industry experts and IIT/NIT alumni
            guides students in robotics and coding, empowering young innovators
            to thrive in a dynamic learning environment.
          </span>
        </div>
        {/* <div className="cards">
          <div className="cards1">
            <div className="image1">
              <img src="Imag.png" alt="" />
              <div className="heading2">
                <p className="hea1">Diwayanshi Shrivastawa</p>
                <p className="hea2">Instructors</p>
                <p className="hea3">
                  Diwayanshi Shrivastava is a dedicated robotics instructor with
                  a B.Tech from MNNIT Allahabad and a Ph.D. from IIM
                  Tiruchirapalli, bringing a wealth of technical expertise and
                  academic insight to her teaching.
                </p>
              </div>
            </div>
          </div>
          <div className="cards1">
            <div className="image1">
              <img src="Imag.png" alt="" />
              <div className="heading2">
                <p className="hea1">Riccky Rai</p>
                <p className="hea2">Instructors</p>
                <p className="hea3">
                  Riccky Rai is an innovative robotics instructor with an M.Tech
                  from AIU, Manipur, combining advanced technical knowledge with
                  a passion for inspiring students in the field of robotics.
                </p>
              </div>
            </div>
          </div>
          <div className="cards1">
            <div className="image1">
              <img src="Imag.png" alt="" />
              <div className="heading2">
                <p className="hea1">Ashwani Jaiswal</p>
                <p className="hea2">Instructors</p>
                <p className="hea3">
                  Ashwani Jaiswal is a skilled robotics instructor with a B.Tech
                  from MNNIT Allahabad, bringing a strong foundation in
                  engineering and a commitment to empowering
                  students in robotics.
                </p>
              </div>
            </div>
          </div>
          <div className="cards1">
            <div className="image1">
              <img src="Imag.png" alt="" />
              <div className="heading2">
                <p className="hea1">Shubham</p>
                <p className="hea2">Instructors</p>
                <p className="hea3">
                  Shubham is a dynamic robotics instructor with an MBA in Data
                  Science from SVSU, Meerut, blending business acumen with
                  technical expertise to inspire and educate students in
                  the robotics field.
                </p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="instructors">
          <div class="card">
            <div class="card-cover">
              <img src="diwayanshi-shrivastawa.jpg" alt="spiderman" />
            </div>
            <h1>Diwayanshi Shrivastawa</h1>
            <p>Instructor</p>
            <p>
              Diwayanshi Shrivastava is a dedicated robotics instructor with a
              B.Tech from MNNIT Allahabad and a Ph.D. from IIM Tiruchirapalli,
              bringing a wealth of technical expertise and academic insight
              to her teaching.
            </p>
          </div>
          <div class="card">
            <div class="card-cover">
              <img src="riccky-rai.jpeg.jpg" alt="spiderman" />
            </div>
            <h1>Riccky Rai</h1>
            <p>Instructor</p>
            <p>
              Riccky Rai is an innovative robotics instructor with an M.Tech
              from AIU, Manipur, combining advanced technical knowledge with a
              passion for inspiring students in the field of robotics.
            </p>
          </div>
          <div class="card">
            <div class="card-cover">
              <img src="ashwani-jaiswal.jpg" alt="spiderman" />
            </div>
            <h1>Ashwani Jaiswal</h1>
            <p>Instructor</p>
            <p>
              Ashwani Jaiswal is a skilled robotics instructor with a B.Tech
              from MNNIT Allahabad, bringing a strong foundation in engineering
              and a commitment to empowering students in robotics.
            </p>
          </div>
          <div class="card">
            <div class="card-cover">
              <img src="shubham.jpg" alt="spiderman" />
            </div>
            <h1>Shubham</h1>
            <p>Instructor</p>
            <p>
              Shubham is a dynamic robotics instructor with an MBA in Data
              Science from SVSU, Meerut, blending business acumen with technical
              expertise to inspire and educate students in the robotics field.
            </p>
          </div>
        </div>
      </section>

      <div className="learning-methods">
        <div className="container">
          <div className="container2">
            <h2 className="learning-methods__title">LEARNING METHODS</h2>
            <h1 className="learning-methods__heading">
              Access to Learn Anytime, Anywhere
            </h1>
            <p className="learning-methods__text">
              Tellus tristique habitant non orci ante ipsum quis. Donec <br />{" "}
              etiam hac in ultrices sed dictumst.
            </p>
            <div className="fullback">
              <div className="learning-methods__cards">
                <div className="learning-methods__card">
                  <div className="learning-methods__card-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        fill="white"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 20L20 15L15 10"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 15H20"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <h3 className="learning-methods__card-title">World Class</h3>
                </div>

                <div className="learning-methods__card">
                  <div className="learning-methods__card-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        fill="white"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 20L20 15L15 10"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 15H20"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <h3 className="learning-methods__card-title">
                    Easy Learning
                  </h3>
                </div>

                <div className="learning-methods__card">
                  <div className="learning-methods__card-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        fill="white"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 20L20 15L15 10"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 15H20"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <h3 className="learning-methods__card-title">Flexible</h3>
                </div>

                <div className="learning-methods__card">
                  <div className="learning-methods__card-icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M15 27.5C21.9036 27.5 27.5 21.9036 27.5 15C27.5 8.09644 21.9036 2.5 15 2.5C8.09644 2.5 2.5 8.09644 2.5 15C2.5 21.9036 8.09644 27.5 15 27.5Z"
                        fill="white"
                        stroke="white"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M15 20L20 15L15 10"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10 15H20"
                        stroke="#A3A3F5"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <h3 className="learning-methods__card-title">Affordable</h3>
                </div>
              </div>
              <div className="button">
                <Link to={"/contact"}>
                  <button className="learning-methods__button">
                    Enroll Now!
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className="lastimg">
              <img src="Ima.jpg" alt="mother and daughter image" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Programs;
