export const data = [
    {
      question: "What age groups or classes does codBee cater to?",
      answer:
        "codBee offers programs for students from Class 3 to Class 12, ensuring age-appropriate and engaging STEM education.",
    },
    {
      question: "How does codBee conduct its classes in schools?",
      answer:
        "Classes are held on-campus, led by dedicated expert trainers who provide hands-on projects, ensuring students gain practical skills in coding and robotics.",
    },
    {
      question: "Do schools need to invest in special equipment or software for codBee programs?",
      answer:
        "codBee provides all necessary tools, software, and equipment, including setups for robotics and STEAM labs, making it hassle-free for schools.",
    },
    {
      question: "How does codBee ensure digital safety during on-campus training?",
      answer:
        "Our trainers follow strict protocols to ensure safe and secure digital practices, protecting students' data and online activities.",
    },
    {
      question: "Are there specific prerequisites for students to join codBee programs?",
      answer:
        "No prior coding or robotics knowledge is required. Our curriculum is designed for beginners and progressively builds their skills.",
    },
    {
      question: "What makes codBee’s curriculum stand out from other educational programs?",
      answer:
        "Our curriculum, developed by IIT alumni and subject matter experts, combines real-world projects, daily essential topics, and a hands-on approach, offering unmatched STEM education.",
    },
    {
      question: "How does codBee address varying learning speeds among students?",
      answer:
        "We provide personalized attention with regular doubt-clearing sessions and customized learning paths, ensuring every student keeps up.",
    },
    {
      question: "What qualifications do codBee trainers bring to schools?",
      answer:
        "Our trainers are industry experts and graduates from prestigious institutions like IITs and NITs, with extensive experience in STEM education.",
    },
    {
      question: "How does codBee assist students who find lessons challenging?",
      answer:
        "We offer additional support with personalized sessions and practical, hands-on examples to help students overcome difficulties.",
    },
    {
      question: "What programming languages and tools do students learn through codBee?",
      answer:
        "Students explore coding languages such as Python, JavaScript, and Scratch, alongside specialized tools for robotics, AI, and STEAM projects.",
    },
    {
      question: "What benefits can schools expect by partnering with codBee?",
      answer:
        "Schools can enhance their educational infrastructure, attract new students, and boost overall academic excellence with codBee’s innovative programs and expert trainers.",
    },
    {
      question: "How does codBee contribute to building future-ready skills in students?",
      answer:
        "Our programs develop critical thinking, problem-solving, and creativity, equipping students with the skills needed to excel in technology-driven careers.",
    },
  ];