import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";

const Gallery = () => {
  const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const images = [
    {
      src: "image 1.jpg",
      description:
        "This image captures the essence of an engaging robotics classes where students are deeply involved in hands-on learning. The girl in the center proudly displays a robotic hand she’s built, symbolizing the real-world applications of the knowledge gained in your robotics classes. The students around her, dressed in their school uniforms, are smiling and actively participating, which shows their excitement and curiosity. This scene reflects the organic and immersive nature of your program—students aren't just sitting through lectures; they are actively building and experimenting with tangible robotics projects. This snapshot showcases the heart of your mission—making learning fun and impactful through hands-on robotics education!",
    },
    {
      src: "image 2.jpg",
      description:
        "In this engaging image, a group of students is actively participating in a robotics class at CodBEE. They are seated around tables, each equipped with a notebook, jotting down ideas and collaborating on concepts. The atmosphere is filled with enthusiasm as they share insights and discuss their projects. The bright classroom setting reflects a dynamic learning environment, where creativity and teamwork thrive. This scene beautifully captures the essence of hands-on learning and the excitement of exploring robotics together.",
    },
    {
      src: "image 3.jpg",
      description:
        "This image showcases the E6-RCU device, an essential tool in CodBEE's robotics education program. The sleek design features a user-friendly interface, highlighting its modern technology. The device is equipped with various buttons and indicators, demonstrating its functionality in controlling robotic systems. Its compact size makes it ideal for classroom use, allowing students to engage hands-on with robotics. The vibrant colors and innovative design capture the excitement of learning in the field of technology. This device symbolizes CodBEE's commitment to providing cutting-edge resources for aspiring young engineers.",
    },
    {
      src: "image 4.jpg",
      description:
        "In this captivating image, a young girl is interacting with a robotic arm, a key feature of CodBEE's robotics education program. She looks intrigued and focused as she explores the mechanics of the arm, demonstrating her engagement in hands-on learning. The robotic arm is intricately designed, showcasing its advanced technology and functionality. The bright classroom setting enhances the excitement, with other students visible in the background, also immersed in their projects. This scene highlights CodBEE's commitment to empowering students through innovative robotics education, fostering curiosity and creativity in the next generation of engineers.",
    },
    {
      src: "image 5.jpg",
      description:
        "This image features a state-of-the-art 3D printer, a vital component of CodBEE's robotics education program. The printer stands prominently in a bright classroom, ready to bring students' creative designs to life. Its sleek, modern design showcases a variety of vibrant filament spools, highlighting the endless possibilities for innovation. As the printer works, it captivates students’ attention, demonstrating the practical application of technology in their projects. This scene embodies CodBEE's commitment to hands-on learning, inspiring young minds to explore the world of robotics and engineering through cutting-edge tools.",
    },
    {
      src: "image 6.jpg",
      description:
        "This image showcases an industrial robot training arm, 3D printed and created by Grade 11 students. This project gives students a practical introduction to industry-grade robotics, allowing them to understand the mechanics, electronics, and coding that go into building functional robotic systems. The hands-on experience of designing a training arm like this helps students grasp real-world applications in industrial automation and technology, making them familiar with concepts they'll encounter in advanced robotics fields. This initiative aligns with the mission of your program to provide students with industry-relevant skills, preparing them for future tech driven careers.",
    },
    {
      src: "image 7.jpg",
      description:
        "In this image, a group of students is actively engaged in a hands-on robotics project. They are gathered around a table covered with various robot parts, including wheels, motors, and chassis components. Each student is intently focused on assembling parts, some holding motors and wiring, while others are working with tools or referencing notes. This collaborative setup fosters teamwork and practical learning as the students explore the fundamentals of robotics assembly and engineering. The background displays posters emphasizing creativity and skills development, aligning with the educational goals of providing real-world technical experience to young learners.",
    },
    {
      src: "image 8.jpg",
      description:
        "This image shows a group of students actively working together to create a robot part using a 3D printer, showcasing the practical and hands-on approach of your robotics program. The students are fully focused as they collaborate, handling various components while the 3D printer operates, emphasizing the integration of modern technology in their learning process. The use of a 3D printer to create robot parts demonstrates how your program equips students with essential skills in both robotics and 3D printing, allowing them to bring their ideas to life. This innovative approach helps students understand the full scope of design, prototyping, and fabrication, providing them with a comprehensive, future-ready skill set. The scene highlights the creative freedom and technical knowledge your classes offer, inspiring students to explore new technologies and develop tangible solutions in robotics.",
    },
    {
      src: "image 10.jpg",
      description:
        "This vibrant group photo captures students from CodBEE’s robotics class, gathered around a state-of-the-art 3D printer. The students, smiling and engaged, showcase a diverse range of backgrounds and interests, united by their passion for technology. The printer stands at the center, symbolizing the innovative projects they are working on together. Bright classroom decorations and materials fill the background, creating an inspiring learning environment. This image highlights the collaborative spirit and excitement of hands-on education at CodBEE, where young minds are encouraged to explore and create with cutting-edge technology.",
    },
  ];

  return (
    <>
      <div className="Gallery">
        <p>Gallery</p>
      </div>
      <div className={`frame ${isVisible ? "visible" : ""}`}>
        <div className="gallery">
          {images.map((image, index) => (
            <div className="image-container" key={index}>
              <img src={image.src} alt={`Gallery Image ${index + 1}`} />
              <div className="overlay">
                <div className="description">{image.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Gallery;
