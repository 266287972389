import React from "react";
import { data } from "./Data";
import { useState } from "react";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom";

const Home = () => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }

    setSelected(i);
  };

  return (
    <div className="Container">
      <section className="herosection">
        <div className="detailsSection">
          <span className="spanHeading">CodBEE - Robotic Course for Kids</span>
          <h6 className="detaildsHeading">
            Empower Your Young Trailblazers to Soar
          </h6>
          <span className="subheading">
            At CodBEE, we deliver top-quality training in Coding, Robotics, AI,
            and Creative Thinking, preparing students for success in today's
            tech-driven world.
          </span>
          <div className="buttonSection">
            <Link to={"/programs"}>
              <button className="exploreButton bg-primary">Our Programs</button>
            </Link>
          </div>
          <div className="trustedcompaniesSeciton">
            <h5 className="testheading">Trusted and certified by:</h5>
            <div className="conpanisesLogo">
              <img
                src="iso-certified.png"
                alt="trusted companies logo"
                className="logosize"
              />
              <img
                src="mca.png"
                alt="trusted companies logo"
                className="logosize"
              />
              <img
                src="msme.png"
                alt="trusted companies logo"
                className="logosize"
              />
              <img
                src="vidyanjali.png"
                alt="trusted companies logo"
                className="logosize"
              />
            </div>
          </div>
        </div>
        <div className="cardsSection">
          <div className="leftpane">
            <div className="how">
              <img
                src="play.png"
                alt="play button image"
                className="playsize"
              />
              <h5>How it Works?</h5>
              <span>You contact us and we will set it up for you.</span>
            </div>
            <div className="cardimg">
              <img src="leftimg.jpg" alt="image" className="cardimgg" />
            </div>
            <div className="totalusers">
              <span>10K +</span>
              <h5>Happy Students</h5>
            </div>
          </div>
          <div className="rightpane">
            <div className="ratingcard">
              <span>
                5.0
                <img
                  src="Star.png"
                  alt="star button image"
                  className="starsize"
                />
              </span>
              <h5>High Rated</h5>
            </div>
            <div className="cardimg">
              <img src="rightimg.jpg" alt="image" className="cardimgg" />
            </div>
            <div className="languagecard">
              <h5>Language :</h5>
              <span className="langTitle">English</span>
              <span className="langTitle">Hindi</span>
              <button className="langBUtton">10+</button>
            </div>
          </div>
        </div>
      </section>
      <section className="whyCodeBeeSection">
        <div className="whyCodeBee">
          <div className="collageImage">
            <img src="image1.jpg" className="collageimg" />
            <img src="image2.jpg" className="collageimg" />
            <img src="image3.jpg" className="collageimg" />
            <img src="image4.jpg" className="collageimg" />
          </div>
          <div className="buttonAndArrow">
            <Link to={"/programs"}>
              <button className="diveInButton">Dive into AI & Robotics</button>
            </Link>
            <svg
              className="arrow"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="25"
              fill="currentColor"
              class="bi bi-arrow-right"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8"
              />
            </svg>
          </div>
        </div>
        <div className="whyChooseUs">
          <p className="whyChooseUsText text-primary">WHY CHOOSE US</p>
          <h5>
            Why CodBEE is the <br /> Perfect Choice
          </h5>
          <span>
            In the AI era, early coding skills are essential-those who <br />{" "}
            delay risk falling behind. Here's why courses stand out:
          </span>
          <div className="listItems">
            <p className="bulletPoints">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#A3A3F5"
                class="bi bi-arrow-right-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              Child-Centric Approach
            </p>
            <p className="bulletPoints">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#A3A3F5"
                class="bi bi-arrow-right-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              Hands-On Learning
            </p>
            <p className="bulletPoints">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#A3A3F5"
                class="bi bi-arrow-right-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              STEM Skill Development
            </p>
            <p className="bulletPoints">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="#A3A3F5"
                class="bi bi-arrow-right-circle-fill"
                viewBox="0 0 16 16"
              >
                <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z" />
              </svg>
              Experienced Educators
            </p>
          </div>
          <div className="enrollNow">
            <p>Enroll Your Child in an Exciting Robotic Journey Today!</p>
            <Link to={"/contact"}>
              <button className="bg-primary text-light">Enroll Now!</button>
            </Link>
          </div>
        </div>
      </section>
      <section className="clientsThoughts">
        <div className="clientSaying">
          <h4>
            What our clients <br /> are saying
          </h4>
        </div>
        <div className="clientCards">
          <div className="clientCards1">
            <div className="clientsReview">
              <div className="iconPng">
                <img src="icon.png" className="commentIcon" />
              </div>
              <p>
                "The practical robotics classes offered by CodBEE have been an
                incredible experience for my child. The hands-on approach really
                sets this program apart, allowing the students to directly apply
                the concepts they learn. My child has built actual robots and
                worked with real components. I am really pleased with how these
                classes are preparing my child for the future!"
              </p>
              <img src="review1.jpg" alt="" className="clientImg" />
            </div>
            <div className="clientsComments">
              <p className="clientsName">Class 11th student</p>
            </div>
          </div>
          <div className="clientCards1">
            <div className="clientsReview">
              <div className="iconPng">
                <img src="icon.png" className="commentIcon" />
              </div>
              <p>
                "I'm impressed with CodBEE's vision for preparing students for a
                tech-driven future through their robotics program. They go
                beyond teaching robotics by fostering creativity,
                problem-solving, and innovation. My child has gained critical
                thinking skills and a deeper understanding of how technology can
                address real-world challenges. CodBEE is truly setting a new
                standard in education, shaping tomorrow's innovators."
              </p>
              <img src="review2.jpg" alt="" className="clientImg" />
            </div>
            <div className="clientsComments">
              <p className="clientsName">Class 6th student</p>
            </div>
          </div>
          <div className="clientCards1">
            <div className="clientsReview">
              <div className="iconPng">
                <img src="icon.png" className="commentIcon" />
              </div>
              <p>
                "I am truly impressed with the robotics classes offered by
                CodBEE. My child has not only gained a deep interest in
                technology but also developed critical thinking and
                problem-solving skills through hands-on activities. The
                instructors are very knowledgeable and patient, making sure that
                each student understands the concepts."
              </p>
              <img src="review3.jpg" alt="" className="clientImg" />
            </div>
            <div className="clientsComments">
              <p className="clientsName">Class 9th student</p>
            </div>
          </div>
          <div className="clientCards1">
            <div className="clientsReview">
              <div className="iconPng">
                <img src="icon.png" className="commentIcon" />
              </div>
              <p>
                "I highly recommend CodBEE's robotics classes for kids. The
                program does more than teach technology—it inspires creativity,
                curiosity, and a love for learning. My child comes home excited
                to share what they've built and has gained confidence and
                problem-solving skills through hands-on experience. If you're
                looking for a program that prepares children for the future,
                these classes are a fantastic investment!"
              </p>
              <img src="review4.jpg" alt="" className="clientImg" />
            </div>
            <div className="clientsComments">
              <p className="clientsName">Class 10th student</p>
            </div>
          </div>
        </div>
      </section>
      <section className="faqSection">
        <div className="faq1">
          <h1>FAQ</h1>
        </div>
        <div className="wrapper">
          <div className="accordian">
            {data.map((item, i) => (
              <div className="item">
                <div className="title" onClick={() => toggle(i)}>
                  <h2>{item.question}</h2>
                  <span>
                    {selected === i ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-caret-up-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-caret-down-fill"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />
                      </svg>
                    )}
                  </span>
                </div>
                <div className={selected === i ? "content show" : "content"}>
                  {item.answer}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
